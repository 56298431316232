/* eslint-disable quotes */
export const appConfig =
{
  "apiKey": "AIzaSyBmMc1z5GdkWYuzuQO64-XZW8nHvwl-QMk",
  "appId": "1:440754980506:web:aa63643674fa74973a4123",
  "authDomain": "schooldog-i-webster-ga.firebaseapp.com",
  "measurementId": "G-62SZ1Q3T02",
  "messagingSenderId": "440754980506",
  "project": "schooldog-i-webster-ga",
  "projectId": "schooldog-i-webster-ga",
  "showGaBranding": true,
  "storageBucket": "schooldog-i-webster-ga.appspot.com"
}
